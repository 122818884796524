//styling
import '../../App.css';
import "../../css/customer_portal.css";
import "../../css/normalize.css";

//react components
import React, { useState, useEffect  } from "react";
import { useSearchParams } from "react-router-dom";

//third-party components
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import Link from '@mui/material/Link';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { Alert, AlertTitle, Container } from "@mui/material";
//customcomponents
import POC from './POC';
import IconSelector from '../IconSelector';

//libraries
import { GetMyPOC } from '../../libs/POC';
import { SendMessage } from '../../libs/Customers';


export default function MyPOC(props){

    const [isLoading, setIsLoading]=useState(true);
    const [poc,setPOC]=useState(props.pocData);
    const [selected, setSelected] = useState();
    const [searchParams,setSearchParams ] = useSearchParams();
    const [contactus,setContactUs] = useState(false);
    const [personName, setPersonName] = useState([]);
    const [message,setMessage]=useState();

    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [showForm, setShowForm]=useState(true);
    const [formSubmitting, setFormSubmitting ]=useState(false);
    const [viewAs, setViewAs] = useState(false);

    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
          },
        },
      };


    const handleChange = (SelectChangeEvent) => {
        
        let tmp = personName;
        tmp.push(SelectChangeEvent.target.value);
        setPersonName(tmp);
    };

    useEffect(()=>{
        if(props.pocData){
            setPOC(props.pocData);
        }
    },[props.pocData])



    useEffect(() => {
        if(!poc && props.isSelected){
            setViewAs((searchParams.get('userId')));
            GetMyPOC(searchParams.get('userId')||props.userId).then((pocdata)=>{
                setPOC(pocdata);
                setIsLoading(false);
                
                if(props.onData){
                    props.onData(pocdata);
                }
            })
            .catch((err)=>{
                console.error('error fetching poc information',err);
                setIsLoading(false);
                if(props.onData){
                    props.onData({});
                }
            });
        }else{
            setIsLoading(false);
        }
        setSelected(props.isSelected);
    
      }, [props.isSelected]);


      const contactIndividual = (indPoc)=>{
        
        setContactUs(true);
        setPersonName([indPoc]);
        
      }

      const contactAll = ()=>{
        setContactUs(true);
        setPersonName(poc);
      }

      const sendMessage = async ()=>{
        
        let recipients = [];
        for(let p of personName){
            recipients.push(p.poc_id);
        }
        //console.log('send messge to:',recipients);
        //console.log('message:',message);
        if(viewAs){
            setSeverity('info');
            setIcon('info');
            setFeedbackMessage('Send Message disabled.');
        }else{
        if(!message || personName.length==0){
            setSeverity('error');
            setIcon('error');
            if(!message){
                setFeedbackMessage('Please enter a message before submitting the form.');
            }
            if(personName.length==0){
                setFeedbackMessage('Please select one or more contacts before submitting the form.');
            }
        }else{

            try{
                setFormSubmitting(true);
                let msg = await SendMessage({ recipients: recipients, message: message})
                setSeverity('success');
                setIcon('verified');
                setFeedbackMessage('Your message was sent successfully.');
                setShowForm(false);
                //setContactUs(false);
                setPersonName([]);
                setMessage('');
            }catch(err){
                setSeverity('error');
                setIcon('error');
                setFeedbackMessage('Error sending your message.  Please try again.');
            }
            setFormSubmitting(false);
        }
        }

        
      }

      const closeDialogue = ()=>{
        setContactUs(false);
        setTimeout(()=>{
            setPersonName([]);
            setSeverity('info');
            setIcon('info');
            setFeedbackMessage();
            setShowForm(true);
        },500);
        
      }

    return(
        (selected)?
        (isLoading || !(poc))?
        <div id="my_poc" className="my_poc">
            <div className="row row_padding">
            <LinearProgress />
            </div>
        </div>
        :
            <div id="my_poc" className="my_poc">
                <div className="row row_padding">
                    <p>Your Abbott Point of Care Contacts are here to support you! <Link href="#" onClick={()=>{contactAll()}} key="sendmessage">Send All Contacts A Message</Link></p>
                    <Grid container rowSpacing={1} columnSpacing={{ xs:1,sm:2, md:3}}>
                    {poc.map(p=><POC key={p.poc_id} pocData={p} contactMe={contactIndividual} />)}
                    </Grid>
                </div>
                <Dialog
                    open={(contactus)}
                    PaperProps={{ style: {
                        minHeight: '500px',
                        maxHeight: '90%',
                        minWidth: '600px',
                        maxWidth: '90%',
                        width: '1024px'
                        }}}
                >
                    <DialogTitle>
                        Send a Message
                    </DialogTitle>
                    <IconButton
                    aria-label="close"
                    onClick={()=>{closeDialogue()}}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        {(formSubmitting)?
                            <LinearProgress />
                            :<></>
                        }
                        <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                            <AlertTitle>{feedbackTitle}</AlertTitle>
                            {feedbackMessage}
                        </Alert>
                        <Container style={{visibility: (showForm) ? 'visible' : 'hidden' }}>
                        <DialogContentText id="alert-dialog-description">
                            Send a message to one or many contacts by completing the following form.
                        </DialogContentText>
                        <FormControl sx={{ m: 1, width: 800,}}>
                            <FormControl sx={{margin:1}}>
                            <Autocomplete
                                disablePortal
                                id="ddlPOC"
                                required
                                multiple
                                options={poc}
                                getOptionLabel={(option) => option.poc_fullname}
                                renderInput={(params) => <TextField {...params} label="Contact Name" />}
                                onChange={(e,v)=>{
                                                    setPersonName(v)
                                                }}
                                value={personName}
                                disabled={formSubmitting}
                            />
                            </FormControl>
                            <FormControl sx={{margin:1}}>
                            <TextField
                                id="poc-message"
                                label="Message"
                                multiline
                                rows={6}
                                placeholder='Type your message here.  Your name and contact details will be automatically provided.'
                                onChange={(obj)=>setMessage(obj.target.value)}
                                disabled={formSubmitting}
                                />
                            </FormControl>
                        </FormControl>
                        <div>
                        <Button 
                            variant="contained" 
                            endIcon={<SendIcon />}
                            onClick={()=>{sendMessage()}}
                        >
                            Send Message
                        </Button>
                        </div>
                        </Container>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
            </div>
        :<></>
        

    )
}



