//styling
import '../../App.css';
import "../../css/customer_portal.css";
import "../../css/normalize.css";
import StaticLogo from "../../images/abbott-logo-sq.jpg"

//react components
import React, { useState, useEffect  } from "react";
import { useSearchParams } from "react-router-dom";

//third-party components
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';

//customcomponents

//libraries



//export default function POC(props){
const POC = (props)=>{
    
    const [poc,setPOC]=useState(props.pocData);
    
    
    useEffect(()=>{
        if(props.pocData){
            setPOC(props.pocData);
        }
    },[props.pocData])

    const contactMe = ()=>{
        if(props.contactMe){
            props.contactMe(poc);
        }
    }

    return(
        
        (!(poc))?
        <div id="my_poc" className="my_poc">
            <div className="row row_padding">
            <LinearProgress />
            </div>
        </div>
        :
    
        <div className="poc_detail">
            <div className="poc_detail_item_container">
                <div className="poc_detail_item">
                <label className="poc_detail_label"></label><label id="poc_fullname"><b>{poc.poc_fullname}</b></label>
                </div>
                <div className="poc_detail_item_sm">
                <label className="poc_detail_label"></label><label id="poc_title">{poc.poc_title}</label>
                </div>
                <div className="poc_detail_item_sm">
                <label className="poc_detail_label"></label><label id="poc_email">{poc.poc_email}</label>
                </div>
                <div className="poc_detail_item_sm">
                <label className="poc_detail_label">Desk:</label><label id="poc_phone">{poc.poc_phone}</label>
                </div>
                <div className="poc_detail_item_sm" id="poc_cell_container">
                <label className="poc_detail_label">Cell:</label><label id="poc_cell">{poc.poc_cell}</label>
                </div>
                <div className="poc_detail_item" id="poc_sendmessageconatiner">
                    <Link href="#" onClick={()=>{contactMe()}} key="sendmessage">Send Me A Message</Link>
                </div>
            </div>
            <div className="poc_image_container">
                <img className="poc_image" src={poc.poc_image || StaticLogo} />
            </div>
        </div>
        
        
    
        
        

    )
}
export default POC;


